import { ElementRef, Renderer2 } from "@angular/core";
import { StandartErrors } from "./StandartErrors.class";
import { cloneDeep } from 'lodash';

export abstract class HelperFunctions {

    public static randomString(length: number): string {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
    }

    public static firstLetterToUppercase(srt: string): string {
        return srt[0].toUpperCase() + srt.slice(1);
    }

    public static parseJson<T>(srt: string, defaultResponse: T): T {
        try {
            return JSON.parse(srt);
        } catch (e) {
            return defaultResponse;
        }
    }

    public static isEmail(str: string): boolean {
        const EMAIL_REGEXP: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        return EMAIL_REGEXP.test(str.trim());
    }

    public static camelize(str: string): string {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }

    public static uniqueArr<T>(arr: T[]): T[] {
        let result: T[] = [];
        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }
        return result;
    }

    public static uniqueArrByField<T>(arr: T[], field: keyof T): T[] {
        let map = new Map<T[keyof T], T>();
        for (let item of arr) {
            if (!map.has(item[field])) {
                map.set(item[field], item);
            }
        }
        return Array.from(map.values());
    }

    public static extractTextNode(root: Node): Node | undefined {
        let iter: NodeIterator = document.createNodeIterator(root, NodeFilter.SHOW_TEXT);
        let textnode: Node | null;
        while (textnode = iter.nextNode()) {
             return textnode;
        }
        return;
    }

    public static *iterateTextNodes(root: Node): Generator<Node, void, undefined> {
        let iter: NodeIterator = document.createNodeIterator(root, NodeFilter.SHOW_TEXT);
        let textNode: Node | null;
        while (textNode = iter.nextNode()) {
            yield textNode;
        }
    }

    public static cloneDeep<T>(object: T): T {
        if (!!structuredClone) {
            return structuredClone(object);
        }
        return cloneDeep(object);
    }

    public static copyElementInArrayById<T extends { id: string | number }>(array: Array<T>, id: number | string): Array<T> {
        let copiedArray: Array<T> = HelperFunctions.cloneDeep(array);
        let foundScriptIndex: number = copiedArray.findIndex(element => element.id === id);
        if (foundScriptIndex === -1) {
            StandartErrors.elementIsNotFound();
        } else {
            let copiedScript: T = HelperFunctions.cloneDeep(array[foundScriptIndex]);
            copiedScript.id = HelperFunctions.randomString(5);
            copiedArray.splice(foundScriptIndex + 1, 0, copiedScript);
        }
        return copiedArray;
    }

    public static excludeExistingElements<T extends { id: string | number }, K extends { id: string | number }>(array: T[], secondArray: K[]): K[] {
        let groupsRecord: Set<string | number> = new Set(array.map((obj: T) => obj.id));
        return secondArray.filter((obj: K) => !groupsRecord.has(obj.id));
    }

    public static setSelectOptionCoords(coords: DOMRect, elementRef: ElementRef, renderer: Renderer2): void {
        if (!(coords instanceof DOMRect)) {
            StandartErrors.invalidDataType();
            return;
        }

        if (!elementRef.nativeElement) {
            StandartErrors.elementIsNotFound();
            return;
        }

        let modalEl: ChildNode | null = elementRef.nativeElement.firstChild;
        if (!modalEl) {
            StandartErrors.elementIsNotFound();
            return;
        }

        renderer.setStyle(modalEl, 'left', coords.left + 'px');
        renderer.setStyle(modalEl, 'top', coords.top + 'px');
        renderer.setStyle(modalEl, 'width', coords.width + 'px');
    }

    /**
     * @param array
     * @param element
     * @param sortBy - поле, по которому отсортирован исходный массив
     * @param byIncreasing - по умолчанию массив считается отсортированным по возрастанию
     */
    public static insertItemInSortedArray<T>(array: T[], element: T, sortBy: keyof T, byIncreasing: boolean = true): T[] {
        let left: number = 0;
        let right: number = array.length - 1;
        let mid: number;

        let compareValues = (arrayValue: T[keyof T], value: T[keyof T]) => {
            return byIncreasing ? arrayValue < value : arrayValue > value;
        }

        while (left <= right) {
            mid = Math.floor((left + right) / 2);
            if (compareValues(array[mid][sortBy],element[sortBy])) {
                left = mid + 1;
            } else {
                right = mid - 1;
            }
        }

        return array.splice(left, 0, element);
    }
}
